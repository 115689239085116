import { jsPDF } from 'jspdf'
import 'jspdf-autotable'
import moment from 'moment'
import API from '@/services/api'

export const printCOMEXPDF = async (request, supplier, year, open = true) => {
    try {
        const {
            data: { settings },
        } = await API.getSettings()
        let company = settings.find(s => s.name == 'Company').generalInfo
        const pdf = new jsPDF({
            orientation: 'portrait',
            unit: 'mm',
            format: [215.9, 279.4],
        })
        let aspectRatio = await getAspectRatio(company.logoBase64)
        const header = function(data) {
            const imgHeight = 20
            const imgWidth = imgHeight * aspectRatio
            const imgX = data.settings.margin.left + 12
            const imgY = 14
            pdf.addImage(
                company.logoBase64,
                'PNG',
                imgX,
                imgY,
                imgWidth,
                imgHeight
            )
        }

        let descriptionOptions = {
            styles: {
                halign: 'center',
                valign: 'middle',
                cellPadding: 1,
                overflow: 'linebreak',
                fontSize: 7,
                lineColor: [0, 0, 0],
                lineWidth: 0.2,
                minCellHeight: 4,
            },
            margin: { top: 35, left: 10 },
            headStyles: {
                textColor: [0, 0, 0],
            },
        }

        const fontSize = 5
        pdf.setFontSize(fontSize)
        const totalWidth = pdf.internal.pageSize.width - 20

        const tableDataInfo = [
            [
                { content: '', rowSpan: 6 },
                {
                    content: 'FOREIGN TRADE MODULE',
                    rowSpan: 6,
                    styles: {
                        fontStyle: 'bold',
                        fontSize: 12,
                    },
                },
                {
                    content: 'CODE',
                    rowSpan: 1,
                    styles: {
                        fontStyle: 'bold',
                    },
                },
            ],
            [
                {
                    content: 'F02-PRFT-02',
                    rowSpan: 1,
                },
            ],
            [
                {
                    content: 'DATE',
                    rowSpan: 1,
                    styles: {
                        fontStyle: 'bold',
                    },
                },
            ],
            [
                {
                    content: '2023-07-01',
                    rowSpan: 1,
                },
            ],
            [
                {
                    content: 'VERSION',
                    rowSpan: 1,
                    styles: {
                        fontStyle: 'bold',
                    },
                },
            ],
            [
                {
                    content: '1',
                    rowSpan: 1,
                },
            ],
            [
                {
                    content: 'IMPORT MANAGEMENT',
                    colSpan: 3,
                    styles: {
                        fillColor: [211, 211, 211],
                        halign: 'left',
                        fontStyle: 'bold',
                    },
                },
            ],
        ]
        const originalWarn = console.warn
        console.warn = () => {
            return null
        }
        pdf.autoTable({
            body: tableDataInfo,
            startY: 10,
            theme: 'grid',
            ...descriptionOptions,
            didDrawPage: function(data) {
                header(data)
            },
            columnStyles: {
                0: { cellWidth: totalWidth * 0.3 },
                1: { cellWidth: totalWidth * 0.4 },
                2: { cellWidth: totalWidth * 0.3 },
            },
        })
        descriptionOptions.startY = pdf.autoTable.previous.finalY
        descriptionOptions.margin = { top: 0, left: 10 }
        descriptionOptions.styles.lineWidth = 0
        const generalInfo = [
            [
                {
                    content: '',
                    colSpan: 6,
                },
            ],
            [
                {
                    content: 'SUPPLIER: ',
                    colSpan: 1,
                    styles: {
                        halign: 'left',
                        fontStyle: 'bold',
                    },
                },
                {
                    content: supplier ? supplier : '',
                    colSpan: 1,
                    styles: {
                        halign: 'left',
                    },
                },
                {
                    content: 'IMPORTATION NUMBER: ',
                    colSpan: 1,
                    styles: {
                        halign: 'left',
                        fontStyle: 'bold',
                    },
                },
                {
                    content: request.number ? request.number : '',
                    colSpan: 1,
                    styles: {
                        halign: 'left',
                    },
                },
                {
                    content: 'YEAR: ',
                    colSpan: 1,
                    styles: {
                        halign: 'right',
                        fontStyle: 'bold',
                    },
                },
                {
                    content: year ? year : '',
                    colSpan: 1,
                    styles: {
                        halign: 'left',
                    },
                },
            ],
            [
                {
                    content: 'INCOTERMS: ',
                    colSpan: 1,
                    styles: {
                        halign: 'left',
                        fontStyle: 'bold',
                    },
                },
                {
                    content: request.incoterm ? request.incoterm : '',
                    colSpan: 1,
                    styles: {
                        halign: 'left',
                    },
                },
                {
                    content: 'MODALITY: ',
                    colSpan: 1,
                    styles: {
                        halign: 'left',
                        fontStyle: 'bold',
                    },
                },
                {
                    content: request.modality ? request.modality : '',
                    colSpan: 1,
                    styles: {
                        halign: 'left',
                    },
                },
            ],
            [
                {
                    content: 'ORIGIN COUNTRY: ',
                    colSpan: 1,
                    styles: {
                        halign: 'left',
                        fontStyle: 'bold',
                    },
                },
                {
                    content: request.originCountry ? request.originCountry : '',
                    colSpan: 1,
                    styles: {
                        halign: 'left',
                    },
                },
                {
                    content: 'ORIGIN CITY: ',
                    colSpan: 1,
                    styles: {
                        halign: 'left',
                        fontStyle: 'bold',
                    },
                },
                {
                    content: request.originCity ? request.originCity : '',
                    colSpan: 1,
                    styles: {
                        halign: 'left',
                    },
                },
            ],
            [
                {
                    content: 'PROYECT: ',
                    colSpan: 1,
                    styles: {
                        halign: 'left',
                        fontStyle: 'bold',
                    },
                },
                {
                    content: request.project ? request.project.name : '',
                    colSpan: 1,
                    styles: {
                        halign: 'left',
                    },
                },
                {
                    content: 'CREATION DATE: ',
                    colSpan: 1,
                    styles: {
                        halign: 'left',
                        fontStyle: 'bold',
                    },
                },
                {
                    content: moment().format('YYYY-MM-DD'),
                    colSpan: 1,
                    styles: {
                        halign: 'left',
                    },
                },
            ],
            [
                {
                    content: '',
                    colSpan: 6,
                },
            ],
        ]
        if (request.modality && request.modality.includes('Plan Vallejo')) {
            generalInfo[4].push(
                {
                    content: 'MAXIMUM EXPORT DATE: ',
                    colSpan: 1,
                    styles: {
                        halign: 'left',
                        fontStyle: 'bold',
                    },
                },
                {
                    content: request.maxExpDate ? request.maxExpDate : '',
                    colSpan: 1,
                    styles: {
                        halign: 'left',
                    },
                }
            )
        }
        const marginLeft = 10
        const tableStartY = pdf.autoTable.previous?.finalY ?? 35
        pdf.autoTable({
            body: generalInfo,
            theme: 'grid',
            tableLineColor: [0, 0, 0],

            ...descriptionOptions,
            columnStyles: {
                0: { cellWidth: totalWidth * 0.18 },
                1: { cellWidth: totalWidth * 0.15 },
                2: { cellWidth: totalWidth * 0.19 },
                3: { cellWidth: totalWidth * 0.15 },
                4: { cellWidth: totalWidth * 0.18 },
                5: { cellWidth: totalWidth * 0.15 },
            },
        })

        let tableEndY = pdf.autoTable.previous?.finalY ?? tableStartY + 20
        let tableWidth = totalWidth
        let tableHeight = tableEndY - tableStartY
        pdf.setLineWidth(0.2)
        pdf.rect(marginLeft, tableStartY, tableWidth, tableHeight)

        if (request.comex) {
            let index = 0
            for (const shipment of request.comex) {
                index += 1
                descriptionOptions.startY = pdf.autoTable.previous.finalY
                descriptionOptions.styles.lineWidth = 0.2
                if (
                    descriptionOptions.startY + 25 >
                    pdf.internal.pageSize.height - 10
                ) {
                    pdf.addPage()
                    descriptionOptions.startY = 10
                }
                const shipmentTitle = [
                    [
                        {
                            content: `Shipment #${index}`,
                            colSpan: 1,
                            styles: {
                                fontStyle: 'bold',
                                fillColor: [211, 211, 211],
                            },
                        },
                    ],
                ]
                pdf.autoTable({
                    body: shipmentTitle,
                    theme: 'grid',
                    tableLineColor: [0, 0, 0],

                    ...descriptionOptions,
                    columnStyles: {
                        0: { cellWidth: totalWidth },
                    },
                })

                descriptionOptions.startY = pdf.autoTable.previous.finalY
                descriptionOptions.styles.lineWidth = 0
                const shipmentInfo = [
                    [
                        {
                            content: '',
                            colSpan: 4,
                        },
                    ],
                    [
                        {
                            content: 'INVOICE NUMBER:',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: shipment.invoiceNumber
                                ? shipment.invoiceNumber
                                : '',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                            },
                        },
                        {
                            content: 'TRM:',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: shipment.trm
                                ? await formatCurrency('USD', shipment.trm)
                                : '',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                            },
                        },
                    ],
                    [
                        {
                            content: '',
                            colSpan: 4,
                        },
                    ],
                ]
                const marginLeft = 10
                const tableStartY = pdf.autoTable.previous?.finalY ?? 35
                pdf.autoTable({
                    body: shipmentInfo,
                    theme: 'grid',
                    tableLineColor: [0, 0, 0],
                    ...descriptionOptions,
                    columnStyles: {
                        0: { cellWidth: totalWidth * 0.25 },
                        1: { cellWidth: totalWidth * 0.25 },
                        2: { cellWidth: totalWidth * 0.25 },
                        3: { cellWidth: totalWidth * 0.25 },
                    },
                })

                let tableEndY =
                    pdf.autoTable.previous?.finalY ?? tableStartY + 20
                let tableWidth = totalWidth
                let tableHeight = tableEndY - tableStartY
                pdf.setLineWidth(0.2)
                pdf.rect(marginLeft, tableStartY, tableWidth, tableHeight)
                descriptionOptions.startY = pdf.autoTable.previous.finalY
                let cellHeight = 16
                if (shipment.items) {
                    cellHeight += shipment.items.length * 8
                }
                if (
                    descriptionOptions.startY + cellHeight >
                    pdf.internal.pageSize.height - 10
                ) {
                    pdf.addPage()
                    descriptionOptions.startY = 10
                }
                descriptionOptions.styles.lineWidth = 0.2
                const headerItems = [
                    [
                        {
                            content: 'OC',
                            colSpan: 1,
                            styles: {
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: 'WEIGHT (Kg)',
                            colSpan: 1,
                            styles: {
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: 'UNITS',
                            colSpan: 1,
                            styles: {
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: 'UNIT WEIGHT (Kg)',
                            colSpan: 1,
                            styles: {
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: 'USD VALUE',
                            colSpan: 1,
                            styles: {
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: 'COP VALUE',
                            colSpan: 1,
                            styles: {
                                fontStyle: 'bold',
                            },
                        },
                    ],
                ]
                if (request.modality && request.modality === 'Plan Vallejo') {
                    headerItems[0].unshift(
                        {
                            content: 'PRODUCT',
                            colSpan: 1,
                            styles: {
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: 'CI',
                            colSpan: 1,
                            styles: {
                                fontStyle: 'bold',
                            },
                        }
                    )
                } else {
                    headerItems[0].unshift({
                        content: 'PRODUCT',
                        colSpan: 2,
                        styles: {
                            fontStyle: 'bold',
                        },
                    })
                }

                pdf.autoTable({
                    body: headerItems,
                    theme: 'grid',
                    tableLineColor: [0, 0, 0],
                    ...descriptionOptions,
                    columnStyles: {
                        0: { cellWidth: totalWidth * 0.27 },
                        1: { cellWidth: totalWidth * 0.08 },
                        2: { cellWidth: totalWidth * 0.08 },
                        3: { cellWidth: totalWidth * 0.1 },
                        4: { cellWidth: totalWidth * 0.06 },
                        5: { cellWidth: totalWidth * 0.13 },
                        6: { cellWidth: totalWidth * 0.14 },
                        7: { cellWidth: totalWidth * 0.14 },
                    },
                })

                if (shipment.items) {
                    for (const item of shipment.items) {
                        descriptionOptions.startY =
                            pdf.autoTable.previous.finalY
                        const bodyItems = [
                            [
                                {
                                    content: item.oc ? item.oc : '',
                                    colSpan: 1,
                                    styles: {
                                        halign: 'center',
                                    },
                                },
                                {
                                    content: item.weight ? item.weight : '',
                                    colSpan: 1,
                                    styles: {
                                        halign: 'center',
                                    },
                                },
                                {
                                    content: item.quantity ? item.quantity : '',
                                    colSpan: 1,
                                    styles: {
                                        halign: 'center',
                                    },
                                },
                                {
                                    content:
                                        item.weight && item.quantity
                                            ? (
                                                  item.weight / item.quantity
                                              ).toFixed(2)
                                            : '',
                                    colSpan: 1,
                                    styles: {
                                        halign: 'center',
                                    },
                                },
                                {
                                    content: item.totalPrice
                                        ? await formatCurrency(
                                              'USD',
                                              item.totalPrice
                                          )
                                        : '',
                                    colSpan: 1,
                                    styles: {
                                        halign: 'right',
                                    },
                                },
                                {
                                    content:
                                        item.totalPrice && shipment.trm
                                            ? await formatCurrency(
                                                  'COP',
                                                  shipment.trm * item.totalPrice
                                              )
                                            : '',
                                    colSpan: 1,
                                    styles: {
                                        halign: 'right',
                                    },
                                },
                            ],
                        ]
                        if (
                            request.modality &&
                            request.modality === 'Plan Vallejo'
                        ) {
                            bodyItems[0].unshift(
                                {
                                    content: item.description
                                        ? item.description
                                        : '',
                                    colSpan: 1,
                                    styles: {
                                        halign: 'left',
                                    },
                                },
                                {
                                    content: item.ci ? item.ci : '',
                                    colSpan: 1,
                                    styles: {
                                        halign: 'center',
                                    },
                                }
                            )
                        } else {
                            bodyItems[0].unshift({
                                content: item.description
                                    ? item.description
                                    : '',
                                colSpan: 2,
                                styles: {
                                    halign: 'left',
                                },
                            })
                        }
                        pdf.autoTable({
                            body: bodyItems,
                            theme: 'grid',
                            tableLineColor: [0, 0, 0],
                            ...descriptionOptions,
                            columnStyles: {
                                0: { cellWidth: totalWidth * 0.27 },
                                1: { cellWidth: totalWidth * 0.08 },
                                2: { cellWidth: totalWidth * 0.08 },
                                3: { cellWidth: totalWidth * 0.1 },
                                4: { cellWidth: totalWidth * 0.06 },
                                5: { cellWidth: totalWidth * 0.13 },
                                6: { cellWidth: totalWidth * 0.14 },
                                7: { cellWidth: totalWidth * 0.14 },
                            },
                        })
                    }
                }
                descriptionOptions.startY = pdf.autoTable.previous.finalY
                const footerItems = [
                    [
                        {
                            content: 'TOTAL',
                            colSpan: 3,
                            styles: {
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: shipment.totalWeight
                                ? shipment.totalWeight
                                : '',
                            colSpan: 1,
                            styles: {
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: shipment.totalQuantity
                                ? shipment.totalQuantity
                                : '',
                            colSpan: 1,
                            styles: {
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: '',
                            colSpan: 1,
                            styles: {
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: shipment.totalUSD
                                ? await formatCurrency('USD', shipment.totalUSD)
                                : '',
                            colSpan: 1,
                            styles: {
                                halign: 'right',
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: shipment.totalCOP
                                ? await formatCurrency('COP', shipment.totalCOP)
                                : '',
                            colSpan: 1,
                            styles: {
                                halign: 'right',
                                fontStyle: 'bold',
                            },
                        },
                    ],
                ]
                pdf.autoTable({
                    body: footerItems,
                    theme: 'grid',
                    tableLineColor: [0, 0, 0],
                    ...descriptionOptions,
                    columnStyles: {
                        0: { cellWidth: totalWidth * 0.27 },
                        1: { cellWidth: totalWidth * 0.08 },
                        2: { cellWidth: totalWidth * 0.08 },
                        3: { cellWidth: totalWidth * 0.1 },
                        4: { cellWidth: totalWidth * 0.06 },
                        5: { cellWidth: totalWidth * 0.13 },
                        6: { cellWidth: totalWidth * 0.14 },
                        7: { cellWidth: totalWidth * 0.14 },
                    },
                })

                descriptionOptions.startY = pdf.autoTable.previous.finalY
                if (
                    descriptionOptions.startY + 74 >
                    pdf.internal.pageSize.height - 10
                ) {
                    pdf.addPage()
                    descriptionOptions.startY = 10
                }
                descriptionOptions.styles.lineWidth = 0
                const shipmentData = [
                    [
                        {
                            content: '',
                            colSpan: 8,
                        },
                    ],
                    [
                        {
                            content: 'SHIPMENT TYPE:',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: shipment.shipmentType
                                ? shipment.shipmentType
                                : '',
                            colSpan: 1,
                        },
                    ],
                    [
                        {
                            content: 'DEPARTURE DATE:',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: shipment.depDate ? shipment.depDate : '',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                            },
                        },
                        {
                            content: 'PORT OF ARRIVAL:',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: shipment.arrivalPort
                                ? shipment.arrivalPort
                                : '',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                            },
                        },
                        {
                            content: 'NAVIERA:',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: shipment.ShippingLine
                                ? shipment.ShippingLine
                                : '',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                            },
                        },
                        {
                            content: 'MOTONAVE:',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: shipment.motonave ? shipment.motonave : '',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                            },
                        },
                    ],
                    [
                        {
                            content: 'TRANSIT TIME:',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: shipment.transitTime
                                ? shipment.transitTime
                                : '',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                            },
                        },
                        {
                            content: 'ESTIMATED DATE OF ARRIVAL:',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: shipment.estimatedDateArrival
                                ? shipment.estimatedDateArrival
                                : '',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                            },
                        },
                        {
                            content: 'DIM NUMBER:',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: shipment.numberDim
                                ? shipment.numberDim
                                : '',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                            },
                        },
                    ],
                    [
                        {
                            content: 'ACCEPTANCE DATE DIM:',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: shipment.acceptanceDateDIM
                                ? shipment.acceptanceDateDIM
                                : '',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                            },
                        },
                        {
                            content: 'DIM LIFT DATE:',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: shipment.DateOfDIM
                                ? shipment.DateOfDIM
                                : '',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                            },
                        },
                        {
                            content: 'IVA DIM:',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: shipment.ivaDim
                                ? await formatCurrency('USD', shipment.ivaDim)
                                : '',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                            },
                        },
                    ],
                    [
                        {
                            content: 'CONTAINER NUMBER:',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: shipment.containerNumber
                                ? shipment.containerNumber
                                : '',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                            },
                        },
                        {
                            content: 'CONTAINER SIZE:',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: shipment.containerSize
                                ? shipment.containerSize
                                : '',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                            },
                        },
                        {
                            content: 'TRANSPORT DOCUMENT:',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: shipment.transportDoc
                                ? shipment.transportDoc
                                : '',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                            },
                        },
                        {
                            content: 'DATE OF TRANSPORT DOCUMENT:',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: shipment.dateDocTransport
                                ? shipment.dateDocTransport
                                : '',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                            },
                        },
                    ],
                    [
                        {
                            content: 'FOB DIM VALUE:',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: shipment.fobDimValue
                                ? await formatCurrency(
                                      'USD',
                                      shipment.fobDimValue
                                  )
                                : '',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                            },
                        },
                        {
                            content: 'FOB UNIT VALUE:',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: shipment.unitFOB
                                ? await formatCurrency('USD', shipment.unitFOB)
                                : '',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                            },
                        },
                    ],
                    [
                        {
                            content: 'FREIGHT:',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: shipment.freight
                                ? await formatCurrency('USD', shipment.freight)
                                : '',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                            },
                        },
                        {
                            content: 'INSURANCE:',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: shipment.insurance
                                ? await formatCurrency(
                                      'USD',
                                      shipment.insurance
                                  )
                                : '',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                            },
                        },
                        {
                            content: 'OTHER COSTS:',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: shipment.otherCosts
                                ? await formatCurrency(
                                      'USD',
                                      shipment.otherCosts
                                  )
                                : '',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                            },
                        },
                        {
                            content: 'TOTAL CUSTOM VALUE:',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: shipment.totalCustoms
                                ? await formatCurrency(
                                      'USD',
                                      shipment.totalCustoms
                                  )
                                : '',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                            },
                        },
                    ],
                    [
                        {
                            content: '',
                            colSpan: 4,
                        },
                    ],
                    [
                        {
                            content: 'ACTUAL DATE OF ARRIVAL:',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: shipment.realDateArrival
                                ? shipment.realDateArrival
                                : '',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                            },
                        },
                        {
                            content: 'DATE ENTERED WAREHOUSE:',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                                fontStyle: 'bold',
                            },
                        },
                        {
                            content: shipment.enteredDate
                                ? shipment.enteredDate
                                : '',
                            colSpan: 1,
                            styles: {
                                halign: 'left',
                            },
                        },
                    ],
                    [
                        {
                            content: '',
                            colSpan: 4,
                        },
                    ],
                ]
                const marginLeft1 = 10
                const tableStartY1 = descriptionOptions.startY
                pdf.autoTable({
                    body: shipmentData,
                    theme: 'grid',
                    tableLineColor: [0, 0, 0],
                    ...descriptionOptions,
                    columnStyles: {
                        0: { cellWidth: totalWidth * 0.125 },
                        1: { cellWidth: totalWidth * 0.125 },
                        2: { cellWidth: totalWidth * 0.125 },
                        3: { cellWidth: totalWidth * 0.125 },
                        4: { cellWidth: totalWidth * 0.125 },
                        5: { cellWidth: totalWidth * 0.125 },
                        6: { cellWidth: totalWidth * 0.125 },
                        7: { cellWidth: totalWidth * 0.125 },
                    },
                })

                let tableEndY1 = pdf.autoTable.previous.finalY
                let tableWidth1 = totalWidth
                let tableHeight1 = tableEndY1 - tableStartY1
                pdf.setLineWidth(0.2)
                pdf.rect(marginLeft1, tableStartY1, tableWidth1, tableHeight1)
            }
        }

        console.warn = originalWarn
        const pdfDataUri = pdf.output('datauristring')
        const blob = dataURItoBlob(pdfDataUri)
        if (open) {
            const blobURL = URL.createObjectURL(blob)
            const printWindow = window.open(blobURL)
            printWindow.onload = function() {
                URL.revokeObjectURL(blobURL)
            }
        }
        return blob
    } catch (error) {
        console.log(error)
    }
}

const getAspectRatio = async file => {
    return new Promise(function(resolved) {
        var i = new Image()
        i.onload = function() {
            resolved(i.width / i.height)
        }
        i.src = file
    })
}

const formatCurrency = async (currency, value) => {
    return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: currency,
        maximumFractionDigits: 2,
    }).format(value)
}

const dataURItoBlob = dataURI => {
    const byteString = Uint8Array.from(atob(dataURI.split(',')[1]), c =>
        c.charCodeAt(0)
    )
    const blobType = dataURI
        .split(',')[0]
        .split(':')[1]
        .split(';')[0]
    return new Blob([byteString], { type: blobType })
}
